/*--------------------------------------------------------------
# News
--------------------------------------------------------------*/
.news {
    .nav-tabs {
        border: 0;
        .nav-item {
            .nav-link {
                height: 6rem;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: row;
                border: 1px solid #b9b9b9;
                padding: 1rem;
                transition: 0.3s;
                color: rgba(17, 17, 17, 1);
                border-radius: 0.3rem;
                gap: 0.5rem;
                cursor: pointer;
                @media (max-width: 992px) {
                    flex-direction: column;
                    height: 8.5rem;
                }
                i {
                    font-size: 36px;
                    margin: 0;
                    @media (max-width: 768px) {
                        padding: 0;
                        line-height: 1;
                        font-size: 32px;
                    }
                    @media (max-width: 575px) {
                        font-size: 24px;
                    }
                }
                h4 {
                    font-size: 16px;
                    font-weight: 600;
                    margin: 0;
                    @media (max-width: 1200px) {
                        font-size: 14px;
                    }
                    @media (max-width: 992px) {
                        font-size: 12px;
                        text-align: center;
                    }
                }
                &:hover {
                    color: var(--oksijen-turuncu);
                }
                &.active {
                    background: var(--oksijen-turuncu);
                    color: rgba(255, 255, 255, 1);
                    border-color: var(--oksijen-turuncu);
                }
            }
        }
    }
    .tab-content {
        margin-top: 30px;
        .tab-pane {
            h3 {
                font-weight: 600;
                font-size: 26px;
            }
            p:last-child {
                margin-bottom: 0;
            }
        }
    }
}
